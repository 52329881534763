<template>
  <div :v-loading="loading">
    <div class="wrap desktop-present">
      <div class="title">
        <div class="title-log">
          <div class="title-img">
            <div class="bg-img">
              <a href="picture.html" target="_blank"></a>
            </div>
          </div>
          <div class="title-white">
            <a href="picture.html" target="_blank">江苏图片库</a>
          </div>
        </div>
        <div class="nav">
          <div class="other">
            <div class="select-search">
              <div class="select">
                <el-select v-model="searchkey" filterable placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="search" v-if="searchkey == 1">
                <el-input class="serarch-input" v-model="search" placeholder="所搜即所得" @keyup.enter.native="onSearch"></el-input>
                <div class="icon el-icon-search" @click="onSearch"></div>
              </div>
              <div class="picSearch" v-else @click="onPicSearch">
                以图搜图
                <span class="el-icon-search"></span>
              </div>
            </div>
            <div class="login">
              <div class="nologin" v-if="loginedStatus == -1 || loginedStatus == 2">
                <!-- <el-input v-model="logoName" placeholder="用户名"></el-input>
                <el-input v-model="passWord" placeholder="密码" show-password></el-input>
                <div class="backpass" @click="onForgetpswd">找回密码>></div>
                <el-input v-model="code" placeholder="验证码" @keyup.enter.native="onSureLogo"></el-input>
                <img :src="codeImg" alt="" class="yan-img" @click="onRetrieveCode" /> -->
                <div class="noLogo">
                  <el-button class="logoin" @click="onSureLogin" type="danger">登录</el-button>
                  <el-button class="register" @click="onRegister(1)">注册</el-button>
                </div>
              </div>

              <div class="mine" v-if="loginedStatus == 0" @click="onMyInfo"></div>
              <div class="upload-buttons">
                <div class="up" v-if="loginedStatus == 0 && noUpload && picUpload" @click="onUpload"></div>
                <div class="uploadVideo" v-if="loginedStatus == 0 && noUpload && videoUpload" @click="onUploadVideo"></div>
              </div>

              <div class="myPost" v-if="loginedStatus == 0 && roleTypeShow == false" @click="onMyPost"></div>
              <div class="out" v-if="loginedStatus == 0 || loginedStatus == 20 || loginedStatus == 30" @click="onOutLogin"></div>
              <div class="buy-pic" v-if="loginedStatus == 0 && roleTypeShow == true">
                <el-button type="primary" @click="onAlreadyBuy">已购买</el-button>
                <el-button type="primary" @click="onShopCar">购物车</el-button>
                <el-button type="primary" @click="onOrder">我的订单</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-content">
        <div class="nav-link">
          <!-- <div class="nav-list" v-for="(k, nIndex) in navData" :key="nIndex + 'na_'" :class="{ active: clickIndex == nIndex }" @click="onClickNav(nIndex, k.name)">
           -->
          <!-- <div class="nav-list" v-for="(k, nIndex) in navData" :key="nIndex + 'na_'" :class="{ active: clickIndex == nIndex }">
            <a v-if="k.dis" @click="onUserMore">{{ k.name }}</a>
            <a v-else :href="k.url" target="_blank">{{ k.name }}</a>
          </div> -->
          <div class="nav-detail" v-for="(k, navIndex) in navData" :key="k + 'nav_' + navIndex" :class="{ active: clickIndex == navIndex }">
            <div class="nav-item">
              <a v-if="k.dis" @click="onNoOpen(k.name)">{{ k.name }}</a>
              <a v-else :href="k.url">{{ k.name }}</a>
              <div class="drop el-icon-arrow-down" v-if="k.drop"></div>
            </div>
            <div
              class="nav-detail-subs"
              :class="{
                active: dropIndexLevel1 == navIndex,
              }"
            >
              <div class="nav-detail-seq" v-for="(p, pindex) in k.subs" :key="p + 'navseq_' + pindex">
                <a v-if="p.dis" @click="onNoOpen(k.name)">{{ p.name }}</a>
                <a v-else :href="p.url">{{ p.name }}</a>
              </div>
            </div>
          </div>
          <!-- <div class="nav-buy">
            <div @click="onClickNav('购买图片')">购买图片</div>
            <div @click="onClickNav('上传图片')">上传图片</div>
          </div> -->
        </div>
      </div>
      <!-- 二维码进小程序 -->
      <div class="wxQR">
        <div class="wx-out">
          <div class="wx-text">手机传稿</div>
          <div class="wx-img">
            <div class="wxqr"></div>
          </div>
        </div>
      </div>
      <!-- 活动的评选 -->
      <div
        class="activitySelect-con"
        :class="{
          active: activityTopicSelectShow == true,
        }"
      >
        <div class="activitySelect" v-for="(k, selectIndex) in activitySelectList" :key="k + 'as_' + selectIndex">
          <div class="activity-name">您有需要参加的评选活动</div>
          <div class="activity-con">
            <div><span>评选</span> {{ k.voteName }}</div>
            <div><span>来自活动</span> {{ k.activityTopicShortTitle }}</div>
            <div class="activity-btn">
              <div>
                <a :href="'vote.html?id=' + k.id + '&type=' + k.type" target="_blank">马上参加</a>
              </div>
              <div @click="onAfter(selectIndex)">稍后再说</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap mobile-present">
      <div class="title">
        <div class="title-log">
          <div class="title-img">
            <div class="bg-img">
              <a href="picture.html" target="_blank"></a>
            </div>
          </div>
          <div class="title-white">
            <a href="picture.html" target="_blank">江苏图片库</a>
          </div>
        </div>
        <div class="nav">
          <div class="other">
            <div class="select-search">
              <div class="select">
                <el-select v-model="searchkey" filterable placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="search" v-if="searchkey == 1">
                <!-- <el-input class="serarch-input" v-model="search" placeholder="所搜即所得" @keyup.enter.native="onSearch"></el-input> -->
                <div class="icon el-icon-search" @click="onSearch">搜索</div>
              </div>
              <div class="picSearch" v-else @click="onPicSearch">
                以图搜图
                <!-- <span class="el-icon-search"></span> -->
              </div>
            </div>
            <div class="login">
              <div class="nologin" v-if="loginedStatus == -1 || loginedStatus == 2">
                <!-- <el-input v-model="logoName" placeholder="用户名"></el-input>
                <el-input v-model="passWord" placeholder="密码" show-password></el-input>
                <div class="backpass" @click="onForgetpswd">找回密码>></div>
                <el-input v-model="code" placeholder="验证码" @keyup.enter.native="onSureLogo"></el-input>
                <img :src="codeImg" alt="" class="yan-img" @click="onRetrieveCode" /> -->
                <div class="noLogo">
                  <el-button class="logoin" @click="onSureLogin" type="danger">登录</el-button>
                  <el-button class="register" @click="onRegister(1)">注册</el-button>
                </div>
              </div>

              <div class="mine" v-if="loginedStatus == 0" @click="onMyInfo"></div>
              <div class="upload-buttons">
                <div class="up" v-if="loginedStatus == 0 && noUpload" @click="onUpload"></div>
                <div class="uploadVideo" v-if="loginedStatus == 0 && roleTypeShow == false" @click="onUploadVideo"></div>
              </div>
              <div class="myPost" v-if="loginedStatus == 0 && roleTypeShow == false && noUpload" @click="onMyPost"></div>
              <div class="out" v-if="loginedStatus == 0 || loginedStatus == 20" @click="onOutLogin"></div>
              <div class="buy-pic" v-if="loginedStatus == 0 && roleTypeShow == true">
                <el-button type="primary" @click="onAlreadyBuy">已购买</el-button>
                <el-button type="primary" @click="onShopCar">购物车</el-button>
                <el-button type="primary" @click="onOrder">我的订单</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-content">
        <div class="nav-link">
          <!-- <div class="nav-list" v-for="(k, nIndex) in navData" :key="nIndex + 'na_'" :class="{ active: clickIndex == nIndex }" @click="onClickNav(nIndex, k.name)">
           -->
          <!-- <div class="nav-list" v-for="(k, nIndex) in navData" :key="nIndex + 'na_'" :class="{ active: clickIndex == nIndex }">
            <a v-if="k.dis" @click="onUserMore">{{ k.name }}</a>
            <a v-else :href="k.url" target="_blank">{{ k.name }}</a>
          </div> -->
          <div class="nav-detail" v-for="(k, navIndex) in navData" :key="k + 'nav_' + navIndex" :class="{ active: clickIndex == navIndex }">
            <div class="nav-item">
              <a v-if="k.dis" @click="onNoOpen(k.name)">{{ k.name }}</a>
              <a v-else :href="k.url">{{ k.name }}</a>
              <div class="drop el-icon-arrow-down" v-if="k.drop"></div>
            </div>
            <div
              class="nav-detail-subs"
              :class="{
                active: dropIndexLevel1 == navIndex,
              }"
            >
              <div class="nav-detail-seq" v-for="(p, pindex) in k.subs" :key="p + 'navseq_' + pindex">
                <a v-if="p.dis" @click="onNoOpen(k.name)">{{ p.name }}</a>
                <a v-else :href="p.url">{{ p.name }}</a>
              </div>
            </div>
          </div>
          <!-- <div class="nav-buy">
            <div @click="onClickNav('购买图片')">购买图片</div>
            <div @click="onClickNav('上传图片')">上传图片</div>
          </div> -->
        </div>
      </div>
      <!-- 活动的评选 -->
      <div
        class="activitySelect-con"
        :class="{
          active: activityTopicSelectShow == true,
        }"
      >
        <div class="activitySelect" v-for="(k, selectIndex) in activitySelectList" :key="k + 'as_' + selectIndex">
          <div class="activity-name">您有需要参加的评选活动</div>
          <div class="activity-con">
            <div><span>评选</span> {{ k.voteName }}</div>
            <div><span>来自活动</span> {{ k.activityTopicShortTitle }}</div>
            <div class="activity-btn">
              <div>
                <a :href="'vote.html?id=' + k.id + '&type=' + k.type" target="_blank">马上参加</a>
              </div>
              <div @click="onAfter(selectIndex)">稍后再说</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="longinChangeTitle" :visible="$rtm.store.logoedShow" width="50rem" class="dialog" :before-close="handleClose">
      <div class="logo-in" v-if="changType">
        <div class="name"><span>用户名</span><el-input v-model="logoName" placeholder="请输入用户名"></el-input></div>
        <div class="pwd"><span>密码：</span><el-input v-model="passWord" placeholder="请输入密码" show-password></el-input></div>
        <div class="code">
          <span>验证码：</span>
          <el-input v-model="code" placeholder="请输入验证码" clearable></el-input>
          <img :src="codeImg" alt="" class="yan-img" @click="onRetrieveCode" />
        </div>
      </div>
      <div class="logo-intwo" v-if="!changType">
        <div class="name"><span>注册手机：</span><el-input v-model="logoName" placeholder="请输入注册手机"></el-input></div>
        <div class="code">
          <span>图形验证码：</span>
          <el-input v-model="code" placeholder="请输入验证码" clearable></el-input>
          <img :src="codeImg" alt="" class="yan-img" @click="onRetrieveCode" />
        </div>
        <div class="code">
          <span>短信验证码：</span>
          <el-input v-model="mobileCode" placeholder="请输入短信验证码" clearable></el-input>
          <el-button @click="onCaptcha" v-if="numberChange">获取验证短信</el-button>
          <el-button v-else>{{ numberText }}秒后重发</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="backMsg" @click="onForgetpswd" type="danger">找回密码</el-button>
        <el-button type="text" class="backRegister" @click="onRegister(1)">注册</el-button>
        <el-button type="text" class="logintype" @click="onChange">
          <span v-if="changType">短信验证码登录</span>
          <span v-if="!changType">账号密码登录</span>
        </el-button>
        <el-button @click="handleClose">取 消</el-button>
        <el-button v-if="changType" type="primary" @click="onSureLogo">确 定</el-button>
        <el-button v-if="!changType" type="primary" @click="onLoginConfirmMobile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from '@/api/RD'
import sha1 from 'js-sha1'
import RDUtils from '@/api/RDUtils'

export default {
  name: 'Home',
  components: {},
  props: {
    resetNav: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    navCode: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },
  data: function () {
    return {
      logoName: '', // 登录名
      passWord: '', // 密码
      code: '', // 验证码
      search: '', // 搜索的条件
      logoShow: false, // 登录框的显示
      noUpload: false,
      loginedStatus: -1, // 登录的状态
      dropIndexLevel1: -1,
      codeImg: '', // 验证码的图片路径
      loading: false, // 加载的loading效果
      changType: false, // 登录方式的切换
      longinChangeTitle: '短信验证登录',
      numberChange: true, // 验证码的显示
      numberText: 50,
      mobileCode: '',
      options: [
        {
          value: 1,
          label: '关键词',
        },
        {
          value: 2,
          label: '图片',
        },
      ],
      searchkey: 1,
      navData: [
        {
          name: '热图推荐',
          url: '/fixTopic.html?id=1',
        },
        {
          name: '新闻图片',
          url: '/catalog.html?title=新闻',
          subs: [
            { name: '时事政治', id: 2, url: '/fixTopic.html?id=2' },
            { name: '经济发展', id: 3, url: '/fixTopic.html?id=3' },
            { name: '社会万象', id: 4, url: '/fixTopic.html?id=4' },
            { name: '人民生活', id: 5, url: '/fixTopic.html?id=5' },
            { name: '文化体育', id: 6, url: '/fixTopic.html?id=7' },
            { name: '科技教育', id: 7, url: '/fixTopic.html?id=6' },
            { name: '生态环境', id: 8, url: '/fixTopic.html?id=8' },
            { name: '城乡建设', id: 46, url: '/fixTopic.html?id=46' },
          ],
        },
        {
          name: '新闻视频',
          url: '/catalog.html?title=视频',
          subs: [
            { name: '热点', id: 41, url: '/fixTopic.html?id=41' },
            { name: '滚动', id: 42, url: '/fixTopic.html?id=42' },
            { name: '推荐', id: 43, url: '/fixTopic.html?id=43' },
            { name: '科教', id: 44, url: '/fixTopic.html?id=44' },
            { name: '经济', id: 45, url: '/fixTopic.html?id=45' },
            { name: '社会', id: 56, url: '/fixTopic.html?id=56' },
            { name: '民生', id: 57, url: '/fixTopic.html?id=57' },
            { name: '环境', id: 58, url: '/fixTopic.html?id=58' },
          ],
        },
        {
          name: '观·点',
          url: '/fixTopic.html?id=30',
        },
        {
          name: '影赛展台',
          url: '/activity.html',
        },
        {
          name: '红色光影',
          url: '',
          dis: true,
          subs: [
            { name: '1919-1949', id: 47, url: '/fixTopic.html?id=47' },
            { name: '1949-1978', id: 48, url: '/fixTopic.html?id=48' },
            { name: '1978-2012', id: 49, url: '/fixTopic.html?id=49' },
            { name: '2012-', id: 50, url: '/fixTopic.html?id=50' },
            { name: '新华方面军', id: 53, url: '/fixTopic.html?id=53' },
            { name: '红色基地', id: 54, url: '/fixTopic.html?id=54' },
          ],
        },
        {
          name: '先进典型',
          url: '/catalog.html?title=先进典型',
          subs: [
            { name: '时代楷模', id: 36, url: '/fixTopic.html?id=36' },
            { name: '中国好人', id: 34, url: '/fixTopic.html?id=34' },
            { name: '最美人物', id: 35, url: '/fixTopic.html?id=35' },
            { name: '道德模范', id: 37, url: '/fixTopic.html?id=37' },
            { name: '学雷锋标兵', id: 38, url: '/fixTopic.html?id=38' },
          ],
        },
        { name: '知识产权', url: '/fixTopic.html?id=108' },
        {
          name: '千村美景',
          // url: '/catalog.html?title=千村美景',
          url: '/qiancun.html',
          subs: [
            { name: '红色文化', id: 130, url: 'qiancunPost.html?id=130&title=红色文化' },
            { name: '传统村落', id: 131, url: 'qiancunPost.html?id=131&title=传统村落' },
            { name: '旅游发达', id: 132, url: 'qiancunPost.html?id=132&title=旅游发达' },
            { name: '产业兴旺', id: 133, url: 'qiancunPost.html?id=133&title=产业兴旺' },
            { name: '乡风治理', id: 134, url: 'qiancunPost.html?id=134&title=乡风治理' },
          ],
        },
        {
          name: '资料图片',
          url: '/catalog.html?title=资料图片',
          subs: [
            { name: '时事政治', id: 100, url: '/fixTopic.html?id=100' },
            { name: '经济发展', id: 101, url: '/fixTopic.html?id=101' },
            { name: '社会万象', id: 102, url: '/fixTopic.html?id=102' },
            { name: '人民生活', id: 103, url: '/fixTopic.html?id=103' },
            { name: '文化体育', id: 104, url: '/fixTopic.html?id=104' },
            { name: '科技教育', id: 105, url: '/fixTopic.html?id=105' },
            { name: '生态环境', id: 106, url: '/fixTopic.html?id=106' },
            { name: '城乡建设', id: 107, url: '/fixTopic.html?id=107' },
          ],
        },
        {
          name: '地方图库',
          url: '/local.html',
          subs: [
            { name: '南京市', id: 200, url: '/local.html' },
            { name: '苏州市', id: 201, url: '/local.html' },
            { name: '无锡市', id: 230, url: '/local.html' },
            { name: '常州市', id: 230, url: '/local.html' },
            { name: '镇江市', id: 230, url: '/local.html' },
            { name: '扬州市', id: 230, url: '/local.html' },
            { name: '泰州市', id: 230, url: '/local.html' },
            { name: '南通市', id: 230, url: '/local.html' },
            { name: '盐城市', id: 230, url: '/local.html' },
            { name: '淮安市', id: 230, url: '/local.html' },
            { name: '宿迁市', id: 230, url: '/local.html' },
            { name: '徐州市', id: 230, url: '/local.html' },
            { name: '连云港市', id: 230, url: '/local.html' },
          ],
        },
        {
          name: '见报图片',
          url: '',
          dis: true,
          subs: [
            { name: '新华日报', id: 200, url: '', dis: true },
            { name: '扬子晚报', id: 201, url: '', dis: true },
            { name: '南京晨报', id: 230, url: '', dis: true },
            { name: '其他', id: 230, url: '', dis: true },
          ],
        },
        // { name: '视觉动态', url: '/advertisement.html?type=3' },
        // { name: '品牌服务', url: '/advertisement.html?type=2' },
        // { name: '新闻', url: '/catalog.html?title=新闻' },
        // { name: '专题图片', url: '/catalog.html?title=专题图片' },
        // { name: '江苏摄影人', url: '/userShow.html', dis: true },
        { name: '新华影像', url: '/fixTopic.html?id=33' },
      ],
      clickIndex: -1,
      activityTopicSelectShow: false,
      activitySelectList: [],
      roleTypeShow: false, // 用户的角色类型
      videoUpload: false, // 是否上传视频可点击
      picUpload: false, // 是否上传图片可点击
    }
  },
  created() {
    var thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        thiz.loginedStatus = 0
        thiz.$rtm.store.loginStatus = true
        if (data.roleType == 'DOWNLOADER') {
          thiz.roleTypeShow = true
        } else if (data.roleType == 'UPLOADER') {
          thiz.roleTypeShow = false
        }

        if (data.approveStatusName == '未通过') {
          thiz.noUpload = false
        } else if (data.approveStatusName == '已通过') {
          thiz.noUpload = true

          if (data.upPicStores.length != 0) {
            thiz.picUpload = true
          }
          if (data.upVideoStores.length != 0) {
            thiz.videoUpload = true
            thiz.isSelect()
            console.log('jkk', thiz.videoUpload)
          }
        }
      })
      .catch((error) => {
        thiz.loginedStatus = error.code
        this.checkLogin(error)
        this.refreshCode()
      })
    if (this.$route.path == '/catalog.html') {
      if (this.$route.query.title == '新闻图片') {
        thiz.clickIndex = 3
        if (this.$route.path == '/navPost.html') {
          thiz.clickIndex = 3
        }
      } else if (this.$route.query.title == '专题图片') {
        thiz.clickIndex = 4
        if (this.$route.path == '/navPost.html') {
          thiz.clickIndex = 4
        }
      }
    } else if (this.$route.path == '/local.html') {
      thiz.clickIndex = 6
    } else if (this.$route.path == '/userShow.html') {
      thiz.clickIndex = 7
    } else if (this.$route.path == '/userShowList.html') {
      thiz.clickIndex = 7
    } else if (this.$route.path == '/upPost.html') {
      thiz.clickIndex = 11
    } else if (this.$route.path == '/picture.html') {
      thiz.clickIndex = -1
    } else if (this.$route.path == '/search.html') {
      thiz.clickIndex = -1
    } else if (this.$route.path == '/buy.html') {
      thiz.clickIndex = 10
    } else if (this.$route.path == '/advertisement.html') {
      if (this.$route.query.type == 2) {
        thiz.clickIndex = 2
      } else if (this.$route.query.type == 3) {
        thiz.clickIndex = 1
      }
    } else if (this.$route.path == '/fixTopic.html') {
      thiz.clickIndex = 0
    } else if (this.$route.path == '/activity.html') {
      thiz.clickIndex = 5
    }
  },
  mounted() {},
  methods: {
    p(s) {
      return s < 10 ? '0' + s : s
    },
    // 检查是否有可以评选的活动
    isSelect() {
      var thiz = this
      RD.activityTopic()
        .checkVoter()
        .then((data) => {
          if (data.length != 0) {
            thiz.activityTopicSelectShow = true
          }
          thiz.activitySelectList = data
        })
    },
    onChange() {
      this.changType = !this.changType
      if (this.changType) {
        this.longinChangeTitle = '账号密码登录'
      } else {
        this.longinChangeTitle = '短信验证码登录'
      }
    },
    // 注册
    onRegister(type) {
      //   this.$router.push("/register.html");
      this.$rtm.store.logoedShow = false
      this.$router.push({
        path: '/register.html',
        query: { id: type },
      })
    },
    refreshCode() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    onRetrieveCode() {
      this.refreshCode()
    },
    // 登录
    onSureLogin() {
      this.refreshCode()
      this.$rtm.store.logoedShow = true
    },
    onSureLogo() {
      var thiz = this
      thiz.loading = true
      // this.refreshCode()
      if (thiz.logoName == '' || thiz.logoName == undefined) {
        thiz.$message('用户名不能为空')
        return
      }
      if (thiz.passWord == '' || thiz.passWord == undefined) {
        thiz.$message('密码不能为空')
        return
      }
      if (isNaN(thiz.code)) {
        var weekpass = false
        weekpass = !RDUtils.checkWeekPassword(thiz.passWord)
        console.log('week', weekpass)

        RD.pure()
          .user()
          .login(thiz.code, thiz.logoName, sha1(thiz.passWord), weekpass)
          .then((data) => {
            if (this.$rtm.store.logoedShow) {
              this.$rtm.store.logoedShow = false
              thiz.$router.push({
                path: '/catalog.html',
                query: { title: thiz.$rtm.store.logoedFixtopCatalog },
              })
              return
            } else {
              thiz.$rtm.store.userInfo = data
              thiz.loginedStatus = 0
              thiz.roleTypeShow = true
              thiz.code = ''
              thiz.loading = false
              window.location.reload()
              if (data.approveStatusName == '未通过') {
                thiz.noUpload = false
              } else if (data.approveStatusName == '已通过' && data.upPicStores.length != 0) {
                thiz.noUpload = true
                this.$notify({
                  title: '成功',
                  message: '登录成功',
                  type: 'success',
                })
              } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
                this.$confirm('当前不是视频库入口，是否跳转到视频库?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => {
                    let routerUrl = this.$router.resolve({
                      path: '/video.html',
                    })
                    window.open(routerUrl.href, '_blank')
                  })
                  .catch(() => {
                    console.log('del')
                    thiz.onOutLogin()
                  })
              } else if (data.approveStatusName == '已拒绝') {
                var reason = data.refusedReason
                this.$alert(reason, '您当前账户已被拒绝', {
                  confirmButtonText: '确定',
                  type: 'warning',
                })
                  .then(() => {})
                  .catch(() => {})
              } else if (data.approveStatusName == '已通过' && data.upTextStores.length != 0) {
                thiz.$alert('下载用户登录成功')
              }
              // RD.user()
              //   .checkOldUserStatus()
              //   .then((data) => {
              //     console.log('checkOldUserStatus', data)
              //     if (data == 0) {
              //       thiz.$router.replace('/picture.html').catch(() => {
              //         this.$notify({
              //           title: '成功',
              //           message: '登录成功',
              //           type: 'success',
              //         })
              //         thiz.isSelect()
              //       })
              //     } else {
              //       this.$confirm('找到' + data + '个可能属于您遗留账户的稿件，要迁移到当前账户么？', '旧账户稿件转移提示', {
              //         confirmButtonText: '迁移',
              //         cancelButtonText: '取消',
              //         closeOnClickModal: false,
              //         type: 'warning',
              //       })
              //         .then(() => {
              //           RD.user()
              //             .mergeBelongPosts()
              //             .then((data) => {
              //               this.$alert(data + '个稿件迁移完毕', '迁移完毕', {
              //                 confirmButtonText: '确定',
              //               }).then(() => {
              //                 thiz.$router.replace('/picture.html').catch(() => {
              //                   this.$notify({
              //                     title: '成功',
              //                     message: '登录成功',
              //                     type: 'success',
              //                   })
              //                   thiz.isSelect()
              //                 })
              //               })
              //             })
              //         })
              //         .catch(() => {
              //           thiz.$router.replace('/picture.html').catch(() => {
              //             this.$notify({
              //               title: '欢迎您',
              //               message: '登录成功',
              //               type: 'success',
              //             })
              //             thiz.isSelect()
              //           })
              //         })
              //     }
              //   })
            }
          })
          .catch((error) => {
            thiz.loginedStatus = error.code
            this.checkLogin(error)
          })
      } else {
        this.$alert('请输入验证码再登录')
        this.code = ''
      }
    },
    checkLogin(error) {
      const thiz = this
      console.log('loginCode', error)
      thiz.$rtm.store.logoedShow = false
      // console.log('jkl', this.navCode)
      if (error.code == this.navCode && this.navCode != 0) {
        return
      }
      if (error.code == 20) {
        this.$alert(error.msg, '提示', {
          confirmButtonText: '前往验证',
          showClose: false,
          closeOnClickModal: false,
          type: 'warning',
          callback: (action) => {
            thiz.$router.push({
              path: '/checkLogin.html',
            })
          },
        })
      } else if (error.code == 21) {
        // this.$alert(error.msg, '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '退出登录',
        //   type: 'warning',
        //   callback: (action) => {
        //     thiz.resendDualAuthSMS()
        //   },
        // })
        this.$confirm(error.msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '退出登录',
          showClose: false,
          closeOnClickModal: false,
          type: 'warning',
        })
          .then(() => {
            thiz.resendDualAuthSMS()
          })
          .catch(() => {
            this.onOutLogin()
          })
      } else if (error.code == 30) {
        this.$confirm(error.msg, '提示', {
          confirmButtonText: '修改密码',
          cancelButtonText: '退出登录',
          showClose: false,
          closeOnClickModal: false,
          type: 'warning',
        })
          .then(() => {
            thiz.$router.push({
              path: '/resetPassword.html',
            })
          })
          .catch(() => {
            this.onOutLogin()
          })
      } else if (error.code == 2) {
        this.$alert(error.msg, '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
        thiz.code = ''
      } else {
        thiz.code = ''
        thiz.loading = false
      }
    },
    // 5次失效后的验证
    resendDualAuthSMS() {
      RD.pure()
        .user()
        .resendDualAuthSMS()
        .then(() => {
          this.$alert('短信发送成功，是否前往验证', '提示', {
            confirmButtonText: '前往验证',
            showClose: false,
            type: 'warning',
            callback: (action) => {
              thiz.$router.push({
                path: '/checkLogin.html',
              })
            },
          })
        })
        .catch((error) => {
          this.$confirm(error.msg, '提示', {
            confirmButtonText: '重新验证',
            cancelButtonText: '退出登录',
            closeOnClickModal: false,
            type: 'warning',
          })
            .then(() => {
              this.resendDualAuthSMS()
            })
            .catch(() => {
              this.onOutLogin()
            })
        })
    },
    // 手机号登录
    onLoginConfirmMobile() {
      var thiz = this
      if (thiz.logoName == '') {
        this.$notify({
          title: '提示',
          type: 'warning',
          message: '请填写注册手机号',
        })
        return
      }
      if (thiz.mobileCode == '') {
        this.$notify({
          title: '提示',
          type: 'warning',
          message: '短信验证码不能为空',
        })
        return
      }
      this.$rtm.showLoading()
      RD.pure()
        .user()
        .loginByMobileCode(thiz.mobileCode)
        .then(() => {
          thiz.loginState = 2
          this.$rtm.hideLoading()
          thiz.code = ''
          location.reload()
          thiz.isSelect()
        })
        .catch((error) => {
          thiz.load()
          thiz.$message(error.msg)
          thiz.code = ''
          this.$rtm.hideLoading()
        })
    },
    // 获取验证码
    onCaptcha() {
      var thiz = this
      if (thiz.logoName == '') {
        this.$notify({
          title: '提示',
          type: 'warning',
          message: '请填写注册手机号',
        })
        return
      }
      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (thiz.logoName != '' && !mobile.test(thiz.logoName)) {
        thiz.$alert('手机号不合法, 请重新填写')
        return
      }

      if (thiz.code == '') {
        this.$notify({
          title: '提示',
          type: 'warning',
          message: '请填写图形验证码',
        })
        return
      }
      RD.pure()
        .user()
        .requestSMSForLogin(thiz.code, thiz.logoName)
        .then(() => {
          thiz.numberChange = false
          setInterval(() => {
            thiz.numberText--
            if (thiz.numberText == 0) {
              thiz.numberChange = true
              thiz.numberText = 50
            }
          }, 1000)
          thiz.$message('手机发送验证码成功，请注意查收')
        })
        .catch((error) => {
          thiz.$alert(error.msg)
        })
    },
    // 退出登录
    onOutLogin() {
      var thiz = this
      RD.user()
        .logout()
        .then(() => {
          thiz.loginedStatus = -1
          window.location.reload()
          // thiz.$router.replace('/picture.html').catch(() => {
          //   this.$notify({
          //     title: '通知',
          //     message: '您已退出登录',
          //     type: 'success',
          //   })
          // })
        })
    },
    // 上传稿件
    onUpload() {
      this.$router.push('/upPost.html')
    },
    // 我要发片
    onUpPost() {
      this.$router.push('/upPost.html')
    },
    // 用户信息
    onMyInfo() {
      this.$router.push('/myInfo.html')
    },
    onMyPost() {
      this.$router.push('/myPost.html')
    },
    onUploadVideo() {
      this.$router.push('/uploadVideo.html')
    },
    // 搜索
    onSearch() {
      var thiz = this
      thiz.$router.push({
        path: '/search.html',
        query: { keyWord: thiz.search },
      })
    },
    // 以图搜图
    onPicSearch() {
      var thiz = this
      if (this.loginedStatus != -1 && this.loginedStatus == 0) {
        thiz.$router.push({
          path: '/picSearch.html',
        })
      } else {
        thiz.$message('请登录后使用此方法')
      }
    },
    handleClose() {
      this.$rtm.store.logoedShow = false
    },
    // 找回密码
    onForgetpswd() {
      this.$router.push('/forgetPassWord.html')
      this.$rtm.store.logoedShow = false
    },
    onClickNav(name) {
      console.log('name', name)
      if (name == '上传图片') {
        if (this.loginedStatus != -1 && this.loginedStatus == 0) {
          this.$router.push({
            path: '/upPost.html',
          })
        } else {
          this.$alert('您当前处于未登录状态, 请先登录', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
          })
            .then(() => {})
            .catch(() => {})
        }
      } else if (name == '购买图片') {
        if (this.loginedStatus != -1 && this.loginedStatus == 0) {
          this.$router.push({
            path: '/buy.html',
          })
        } else {
          this.$router.push({
            path: '/register.html',
            query: { id: 1, type: 'buy' },
          })
        }
      }
    },
    onUserMore() {
      this.$alert('栏目建设中', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      })
        .then(() => {})
        .catch(() => {})
    },
    // 稍后再说
    onAfter(index) {
      // this.activityTopicSelectShow = false
      this.activitySelectList.splice(index, 1)
    },
    // 已购买
    onAlreadyBuy() {
      let routerUrl = this.$router.resolve({
        path: '/alreadyBuy.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    onShopCar() {
      let routerUrl = this.$router.resolve({
        path: '/shopCar.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    onOrder() {
      let routerUrl = this.$router.resolve({
        path: '/myOrder.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    // 点击展开
    onDropSubs(navIndex) {
      const thiz = this
      if (thiz.dropIndexLevel1 == navIndex) {
        thiz.dropIndexLevel1 = -1
      } else {
        thiz.dropIndexLevel1 = navIndex
      }
    },
    onNoOpen(name) {
      if (name == '红色光影') {
        this.$confirm('当前状态未登录，请选择以下操作?', '提示', {
          confirmButtonText: '现在登录',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 打开登录弹框
            this.$rtm.store.logoedShow = true
            this.$rtm.store.logoedFixtopCatalog = name
          })
          .catch(() => {})
      } else {
        this.$notify({
          title: '提示',
          message: '此网页还在建设中，请稍后',
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.nav {
  width: 100%;
  margin: 0px;
}
.wrap.desktop-present {
  position: relative;
  height: 17rem;
  width: 100%;
  .title {
    background-color: #fff;
    display: flex;
    height: 12rem;
    position: relative;
    width: 120rem;
    margin: 0 auto;
    .title-log {
      width: 38.2rem;
      height: 100%;
      position: absolute;
      left: 0rem;
      top: 0rem;
      .title-img {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: 67%;
        height: 9.5rem;
        border-right: 0.1rem solid #ccc;
        cursor: pointer;
        overflow: hidden;
        .bg-img {
          position: absolute;
          left: -1rem;
          top: -0.6rem;
          width: 26rem;
          height: 10rem;
          background: no-repeat url(../assets/logo.png);
          background-size: cover;
          transform: scale(1.3);
          a {
            display: inline-block;
            width: 22.2rem;
            height: 10rem;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .title-white {
        width: 18.5rem;
        height: 5.1rem;
        line-height: 5.1rem;
        position: absolute;
        left: 67%;
        top: 2.3rem;
        background-color: red;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .nav {
      height: 10.5rem;
      background-color: #fff;
      width: 73.5rem;
      // margin: 0 auto;
      margin-left: 0.5rem;
      overflow: hidden;
      position: absolute;
      right: 0rem;
      top: 0rem;
      .other {
        position: absolute;
        top: 0.6rem;
        right: 0rem;
        display: flex;
        margin-top: 2rem;
        width: 72.8rem;
        .select-search {
          width: 28rem;
          display: flex;
          height: 4rem;
          margin-top: 0.3rem;
          .select {
            width: 8.9rem;
          }
          .search {
            width: 16rem;
            display: flex;
            flex-shrink: 0;
            position: relative;
            .el-input {
              width: 16rem;
              margin: 0rem 0.3rem;
            }
            .serarch-input {
              outline: none !important;
              border: 0rem !important;
              padding-left: 1rem;
            }
            .icon {
              width: 5rem;
              font-size: 2rem;
              padding-top: 0.5rem;
              border-radius: 0.5rem;
              height: 3rem;
              cursor: pointer;
              position: absolute;
              top: 0.4rem;
              right: -0.2rem;
            }
            .height {
              width: 7rem;
              height: 4rem;
              line-height: 4rem;
              font-size: 1.2rem;
              cursor: pointer;
            }
          }
          .picSearch {
            width: 13.6rem;
            margin-left: 2rem;
            border-radius: 0.5rem;
            height: 3.8rem;
            line-height: 3.8rem;
            font-size: 1.2rem;
            border: 0.1rem solid #ccc;
            cursor: pointer;
            position: relative;
            background-color: #dfdfdf;
            span {
              position: absolute;
              top: 0.7rem;
              right: 1rem;
              font-size: 2rem;
            }
          }
        }

        .login {
          display: flex;
          justify-content: flex-end;
          width: 47rem;
          position: relative;
          margin-left: 0.9rem;
          margin-top: 2px;
          .nologin {
            position: relative;
            display: flex;
            justify-content: flex-end;
            width: 47rem;
            position: relative;
            margin-left: 0.9rem;
            margin-top: 2px;
            .backpass {
              position: absolute;
              width: 8rem;
              height: 3rem;
              top: 4.5rem;
              left: 22.7rem;
              font-size: 1.4rem;
              cursor: pointer;
            }
            .el-input {
              width: 16rem;
              padding: 0rem;
              margin: 0rem 0.1rem;
            }
            .yan {
              width: 5rem;
              border: 0.1rem solid #ccc;
              line-height: 4.5rem;
              height: 3.8rem;
              margin-right: 0.3rem;
            }
            .yan-img {
              width: 8.5rem;
              height: 4rem;
              margin-right: 0.2rem;
              cursor: pointer;
            }
            .noLogo {
              .el-button {
                width: 9rem;
                height: 4rem;
                margin: 0rem;
                padding: 0rem;
              }
              .register {
                // height: 3rem;
                margin-left: 1.5rem;
              }
            }
          }

          // .logoed {
          //   display: flex;
          //   font-size: 1.2rem;
          //   height: 4.5rem;
          //   line-height: 4.5rem;
          //   position: absolute;
          //   top: 0rem;
          //   right: 0rem;
          div {
            margin-left: 0.5rem;
            // padding: 0rem 1rem;
          }
          .up {
            height: 4rem;
            width: 8.7rem;
            margin-left: 0px;
            margin-top: 0px;
            cursor: pointer;
            background: no-repeat url(../assets/uploadpicbefore.png);
            background-size: contain;
          }
          .up:hover {
            background: no-repeat url(../assets/uploadpic.png);
            background-size: contain;
          }
          .mine {
            width: 8.7rem;
            margin-top: 0.5rem;
            height: 4rem;
            cursor: pointer;
            background: no-repeat url(../assets/myinfobefore.png);
            background-size: contain;
          }
          .mine:hover {
            background: no-repeat url(../assets/myinfo.png);
            background-size: contain;
          }
          .myPost {
            width: 8.8rem;
            height: 4rem;
            cursor: pointer;
            margin-top: 0.5rem;
            background: no-repeat url(../assets/mystorebefore.png);
            background-size: contain;
          }
          .myPost:hover {
            background: no-repeat url(../assets/mystore.png);
            background-size: contain;
          }
          .uploadVideo {
            width: 8.7rem;
            height: 4rem;
            margin-left: 0px;
            margin-top: 0px;
            cursor: pointer;
            background: no-repeat url(../assets/upvideo.png);
            background-size: contain;
          }
          .uploadVideo:hover {
            background: no-repeat url(../assets/upvideoAfter.png);
            background-size: contain;
          }
          .out {
            width: 8.7rem;
            margin-top: 0.5rem;
            height: 4rem;
            cursor: pointer;
            background: no-repeat url(../assets/loginoutbefore.png);
            background-size: contain;
          }
          .out:hover {
            background: no-repeat url(../assets/loginout.png);
            background-size: contain;
          }
          .upload-buttons {
            height: 7.5rem;
            margin-top: 0.6rem;
            display: flex;
            flex-direction: column;
            padding: 0px;
          }
        }
        .buy-pic {
          padding: 0px !important;
          .el-button {
            height: 4rem;
            font-size: 1.5rem;
            padding: 0.6rem;
          }
        }
        // }
      }
    }
    .title-right {
      width: 50%;
      .search {
        text-align: right;
        .el-input {
          width: 27.2rem;
          margin-top: 2rem;
        }
        .el-button {
          margin-left: 2rem;
          height: 4.2rem;
          font-size: 1.5rem;
        }
      }
      .btn {
        height: 4rem;
        display: flex;
        position: relative;
        .welcome {
          text-align: left;
          width: 35%;
          height: 4rem;
          line-height: 4rem;
          position: absolute;
          left: 0rem;
          top: 0rem;
          font-size: 1.4rem;
          cursor: pointer;
          color: #fff;
        }
        .register {
          width: 4rem;
          height: 3rem;
          line-height: 3.1rem;
          position: absolute;
          right: 8rem;
          top: 0.5rem;
          color: #fff;
          font-size: 1.4rem;
          border-right: 0.2rem solid #ccc;
          cursor: pointer;
        }
      }
    }
  }

  .nav-content {
    width: 120rem;
    height: 5rem;
    display: flex;
    background-color: #898989;
    position: relative;
    color: #fff;
    margin: 0px auto;
    .nav-log {
      width: 3rem;
      height: 5rem;
      font-size: 5rem;
      display: flex;
      justify-content: flex-start;
      div {
        width: 3rem;
        height: 5rem;
        font-size: 3rem;
        line-height: 5rem;
      }
    }
    .nav-link {
      width: 120rem;
      height: 5rem;
      margin: 0 auto;
      display: flex;
      .nav-detail {
        flex: 1;
        // width: 14.5rem;
        line-height: 5rem;
        font-size: 1.6rem;
        cursor: pointer;
        position: relative;
        text-align: center;
        transition: 500ms all ease-in-out;
        box-sizing: border-box;
        .nav-item {
          display: flex;
          .drop {
            width: 2.5rem;
            height: 2.5rem;
            line-height: 8.3rem;
          }
          .el-icon-arrow-down {
            color: #fff;
            font-size: 22px;
          }
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
            text-decoration: none;
          }
        }
        .blackt {
          a {
            color: #333;
          }
          .el-icon-arrow-down {
            color: #333;
            font-size: 22px;
          }
        }

        // .red {
        //   color: #d7140c;
        // }
        .nav-detail-subs {
          position: absolute;
          top: 5rem;
          left: 14px;
          width: 90px;
          z-index: 99;
          opacity: 0;
          background-color: #fff;
          display: none;
          color: #000;
          border-radius: 4px 4px 4px 4px;
          // border: 1px solid rgba(153, 153, 153, 0.3);
          box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
          transition: 500ms all ease-in-out;
          .nav-detail-seq {
            font-size: 1.4rem;
            height: 0px;
            color: #000;
            transition: 500ms all ease-in-out;
            cursor: pointer;
            a {
              color: #000;
              text-decoration: none;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .nav-detail-seq:hover {
            color: #fff;
            background: #d7140c;
            a {
              color: #fff;
            }
          }
        }
      }

      .nav-detail:hover {
        a {
          color: #e42121;
          font-weight: bold;
          font-size: 1.5rem;
          // transform: scale(1.03);
          // transition: 100ms all ease-in-out;
        }
        .el-icon-arrow-down {
          color: #d7140c;
        }
        .nav-detail-subs {
          opacity: 1;
          height: auto;
          display: block;
        }
        .nav-detail-seq {
          height: 3rem;
          line-height: 3rem;
          margin: 0.8rem 0px 0px 0px;
          a {
            height: 3rem;
            color: #000;
          }
        }
      }
      .nav-list {
        cursor: pointer;
        font-size: 1.4rem;
        flex: 1;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 5rem;
          color: #fff;
          text-decoration: none;
        }
      }
      .nav-list.active {
        border-bottom: 0.3rem solid red;
      }
      .nav-buy {
        display: flex;
        margin-left: 1.5rem;
        div {
          font-size: 1.4rem;
          line-height: 5rem;
          height: 5rem;
          margin-right: 2rem;
          cursor: pointer;
        }
      }
    }
    .nav-number {
      width: 20rem;
      height: 5rem;
      line-height: 5rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      margin-top: 1rem;
      .fapian {
        width: 7rem;
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        color: red;
        padding-right: 0.5rem;
        cursor: pointer;
      }
      .number {
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        text-align: left;
      }
    }
  }

  .el-button {
    height: 2rem;
    font-size: 1.2rem;
  }

  .wxQR {
    position: fixed;
    top: 20rem;
    right: -19rem;
    width: 22rem;
    height: 18.8rem;
    z-index: 1;
    transition: all 200ms ease-in-out;
    transition-delay: 1s;
    border: 0.3rem solid rgb(133, 131, 131);
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    overflow: hidden;
    .wx-out {
      display: flex;
      height: 100%;
      .wx-text {
        width: 3rem;
        height: 100%;
        writing-mode: tb-rl;
        text-align: center;
        font-size: 1.5rem;
        background: rgb(102, 100, 100);
        padding-right: 0.5rem;
        color: #fff;
        text-shadow: 0rem 0rem 0.5rem #000;
      }
      .wx-img {
        width: 20.5rem;
        height: 100%;
        .wxqr {
          width: 100%;
          height: 100%;
          background: no-repeat url(../assets/wxQR.jpg);
          background-size: contain;
        }
      }
    }
  }
  .wxQR:hover {
    right: 0rem;
    transition-delay: 0s;
    z-index: 9;
  }
  .activitySelect-con {
    height: auto;
    position: fixed;
    bottom: -40vh;
    right: 2rem;
    z-index: 9999;
    transition: all 2000ms ease-in-out;
    .activitySelect {
      width: 25rem;
      height: auto;
      border: 1px solid #ff8c00;
      border-radius: 1rem;
      background-color: #fff;
      margin-bottom: 1rem;
      .activity-name {
        height: 4rem;
        line-height: 4rem;
        font-size: 1.8rem;
        border-bottom: 1px solid #ccc;
      }
      .activity-con {
        font-size: 1.6rem;
        div {
          height: 4rem;
          span {
            margin-right: 0.5rem;
          }
        }
      }
      .activity-btn {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-around;
        div {
          background: #ff8c00;
          color: #fff;
          height: 3rem;
          line-height: 3rem;
          font-size: 1.6rem;
          width: 9rem;
          text-align: center;
          border-radius: 0.5rem;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
  .activitySelect-con.active {
    bottom: 2rem;
  }
}
.wrap.mobile-present {
  position: relative;
  height: 110px;
  width: 750px;
  margin: 0px auto;
  .title {
    background-color: #fff;
    display: flex;
    height: 80px;
    position: relative;
    width: 750px;
    margin: 0 auto;
    .title-log {
      width: 218px;
      height: 100%;
      position: absolute;
      left: 0rem;
      top: 0rem;
      .title-img {
        position: absolute;
        left: 0rem;
        top: 0px;
        width: 144px;
        height: 9.5rem;
        border-right: 0.1rem solid #ccc;
        cursor: pointer;
        overflow: hidden;
        .bg-img {
          position: absolute;
          left: -2rem;
          top: -0.6rem;
          width: 26rem;
          height: 10rem;
          background: no-repeat url(../assets/logo.png);
          background-size: cover;
          transform: scale(1.3);
          a {
            display: inline-block;
            width: 22.2rem;
            height: 10rem;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .title-white {
        width: 80px;
        height: 5.1rem;
        line-height: 5.1rem;
        position: absolute;
        left: 67%;
        top: 2.3rem;
        background-color: red;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .nav {
      height: 80px;
      background-color: #fff;
      width: 520px;
      margin-left: 0.5rem;
      overflow: hidden;
      position: absolute;
      right: 0rem;
      top: 0rem;
      .other {
        position: absolute;
        top: 0.6rem;
        right: 0rem;
        display: flex;
        margin-top: 2rem;
        width: 100%;
        height: 30px;
        .select-search {
          width: 180px;
          display: flex;
          height: 30px;
          margin-top: 0.3rem;
          .select {
            width: 100px;
            height: 30px;
          }
          .search {
            width: 50px;
            display: flex;
            flex-shrink: 0;
            position: relative;
            border: 1px solid #ccc;
            .el-input {
              width: 16rem;
              margin: 0rem 0.3rem;
              height: 30px;
            }
            .serarch-input {
              outline: none !important;
              border: 0rem !important;
              padding-left: 1rem;
            }
            .icon {
              width: 50px;
              font-size: 14px;
              padding-top: 0.5rem;
              border-radius: 0.5rem;
              height: 3rem;
              cursor: pointer;
              position: absolute;
              top: 0.4rem;
              right: -0.2rem;
            }
            .height {
              width: 7rem;
              height: 4rem;
              line-height: 4rem;
              font-size: 1.2rem;
              cursor: pointer;
            }
          }
          ::v-deep.el-input__inner {
            height: 30px !important;
          }
          .picSearch {
            width: 52px;
            flex-shrink: 0;
            margin-left: 2rem;
            border-radius: 0.5rem;
            height: 3.8rem;
            line-height: 3.8rem;
            font-size: 1.2rem;
            border: 0.1rem solid #ccc;
            cursor: pointer;
            position: relative;
            background-color: #dfdfdf;
            span {
              position: absolute;
              top: 0.7rem;
              right: 1rem;
              font-size: 2rem;
            }
          }
        }

        .login {
          display: flex;
          width: 360px;
          position: relative;
          margin-left: 15px;
          display: flex;
          justify-content: flex-end;
          position: relative;
          margin-top: 2px;
          .nologin {
            position: relative;
            display: flex;
            width: 100%;
            position: relative;
            margin-left: 0.9rem;
            margin-top: 2px;
            .backpass {
              position: absolute;
              width: 80px;
              height: 30px;
              top: 42px;
              left: 166px;
              font-size: 1.4rem;
              cursor: pointer;
            }
            .el-input {
              width: 16rem;
              padding: 0rem;
              margin: 0rem 0.1rem;
              ::v-deep .el-input__inner {
                height: 30px;
                margin-top: 0px;
              }
            }
            .yan {
              width: 5rem;
              border: 0.1rem solid #ccc;
              line-height: 40px;
              height: 40px;
              margin-right: 3px;
            }
            .yan-img {
              width: 8.5rem;
              height: 4rem;
              margin-right: 0.2rem;
              cursor: pointer;
            }
            .noLogo {
              display: flex;
              position: relative;
              .el-button {
                width: 9rem;
                height: 4rem;
                margin: 0rem;
                padding: 0rem;
              }
              .register {
                position: absolute;
                top: 4rem;
                height: 3rem;
                margin-top: 0.5rem;
              }
            }
          }
          div {
            margin-left: 0.5rem;
            padding: 0rem 1rem;
          }
          .up {
            width: 8.7rem;
            height: 4rem;
            margin-left: 0px;
            margin-top: 0px;
            cursor: pointer;
            background: no-repeat url(../assets/uploadpicbefore.png);
            background-size: contain;
          }
          .up:hover {
            background: no-repeat url(../assets/uploadpic.png);
            background-size: contain;
          }
          .mine {
            width: 8.7rem;
            height: 4rem;
            margin-top: 0.5rem;
            cursor: pointer;
            background: no-repeat url(../assets/myinfobefore.png);
            background-size: contain;
          }
          .mine:hover {
            background: no-repeat url(../assets/myinfo.png);
            background-size: contain;
          }
          .myPost {
            width: 8.8rem;
            height: 4rem;
            cursor: pointer;
            margin-top: 0.5rem;
            background: no-repeat url(../assets/mystorebefore.png);
            background-size: contain;
          }
          .myPost:hover {
            background: no-repeat url(../assets/mystore.png);
            background-size: contain;
          }
          .out {
            width: 8.7rem;
            height: 4rem;
            margin-top: 0.5rem;
            cursor: pointer;
            background: no-repeat url(../assets/loginoutbefore.png);
            background-size: contain;
          }
          .out:hover {
            background: no-repeat url(../assets/loginout.png);
            background-size: contain;
          }
          .uploadVideo {
            width: 8.7rem;
            height: 4rem;
            margin-left: 0px;
            margin-top: 0px;
            cursor: pointer;
            background: no-repeat url(../assets/upvideo.png);
            background-size: contain;
          }
          .uploadVideo:hover {
            background: no-repeat url(../assets/upvideoAfter.png);
            background-size: contain;
          }
          .upload-buttons {
            height: 7.5rem;
            margin-top: 0.6rem;
            display: flex;
            flex-direction: column;
            padding: 0px;
          }
        }
      }
    }
    .title-right {
      width: 50%;
      .search {
        text-align: right;
        .el-input {
          width: 27.2rem;
          margin-top: 2rem;
        }
        .el-button {
          margin-left: 2rem;
          height: 4.2rem;
          font-size: 1.5rem;
        }
      }
      .btn {
        height: 4rem;
        display: flex;
        position: relative;
        .welcome {
          text-align: left;
          width: 35%;
          height: 4rem;
          line-height: 4rem;
          position: absolute;
          left: 0rem;
          top: 0rem;
          font-size: 1.4rem;
          cursor: pointer;
          color: #fff;
        }
        .register {
          width: 4rem;
          height: 20px;
          line-height: 3.1rem;
          position: absolute;
          right: 8rem;
          top: 0.5rem;
          color: #fff;
          font-size: 1.4rem;
          border-right: 0.2rem solid #ccc;
          cursor: pointer;
        }
      }
    }
  }

  .nav-content {
    width: 100%;
    height: 5rem;
    display: flex;
    background-color: #898989;
    position: relative;
    color: #fff;
    .nav-log {
      width: 3rem;
      height: 5rem;
      font-size: 5rem;
      display: flex;
      justify-content: flex-start;
      div {
        width: 3rem;
        height: 5rem;
        font-size: 3rem;
        line-height: 5rem;
      }
    }
    .nav-link {
      width: 120rem;
      height: 5rem;
      margin: 0 auto;
      display: flex;
      .nav-detail {
        flex: 1;
        // width: 14.5rem;
        line-height: 5rem;
        font-size: 1.4rem;
        cursor: pointer;
        position: relative;
        text-align: center;
        transition: 500ms all ease-in-out;
        box-sizing: border-box;
        .nav-item {
          display: flex;
          .drop {
            width: 2.5rem;
            height: 2.5rem;
            line-height: 8.3rem;
          }
          .el-icon-arrow-down {
            color: #fff;
            font-size: 22px;
          }
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
            text-decoration: none;
          }
        }
        .blackt {
          a {
            color: #333;
          }
          .el-icon-arrow-down {
            color: #333;
            font-size: 22px;
          }
        }

        // .red {
        //   color: #d7140c;
        // }
        .nav-detail-subs {
          position: absolute;
          top: 5rem;
          left: 14px;
          width: 90px;
          z-index: 99;
          opacity: 0;
          background-color: #fff;
          color: #000;
          border-radius: 4px 4px 4px 4px;
          // border: 1px solid rgba(153, 153, 153, 0.3);
          box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
          transition: 500ms all ease-in-out;
          .nav-detail-seq {
            font-size: 1.4rem;
            height: 0px;
            color: #000;
            transition: 500ms all ease-in-out;
            cursor: pointer;
            a {
              color: #000;
              text-decoration: none;
              height: 3rem;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .nav-detail-seq:hover {
            color: #fff;
            background: #d7140c;
            a {
              color: #fff;
            }
          }
        }
      }

      .nav-detail:hover {
        a {
          color: #e42121;
          font-weight: bold;
          font-size: 1.5rem;
          // transform: scale(1.03);
          // transition: 100ms all ease-in-out;
        }
        .el-icon-arrow-down {
          color: #d7140c;
        }
        .nav-detail-subs {
          opacity: 1;
          height: auto;
        }
        .nav-detail-seq {
          height: 3rem;
          line-height: 3rem;
          margin: 0.8rem 0px 0px 0px;
          a {
            height: 3rem;
            color: #000;
          }
        }
      }
      .nav-list {
        cursor: pointer;
        font-size: 1.4rem;
        flex: 1;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 5rem;
          color: #fff;
          text-decoration: none;
        }
      }
      .nav-list.active {
        border-bottom: 0.3rem solid red;
      }
      .nav-buy {
        display: flex;
        margin-left: 1.5rem;
        div {
          font-size: 1.4rem;
          line-height: 5rem;
          height: 5rem;
          margin-right: 2rem;
          cursor: pointer;
        }
      }
    }
    .nav-number {
      width: 20rem;
      height: 5rem;
      line-height: 5rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      margin-top: 1rem;
      .fapian {
        width: 7rem;
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        color: red;
        padding-right: 0.5rem;
        cursor: pointer;
      }
      .number {
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        text-align: left;
      }
    }
  }

  .el-button {
    height: 2rem;
    font-size: 1.2rem;
  }

  .wxQR {
    position: fixed;
    top: 20rem;
    right: -19rem;
    width: 22rem;
    height: 18.8rem;
    z-index: 18;
    transition: all 200ms ease-in-out;
    transition-delay: 1s;
    border: 0.3rem solid rgb(133, 131, 131);
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    overflow: hidden;
    .wx-out {
      display: flex;
      height: 100%;
      .wx-text {
        width: 3rem;
        height: 100%;
        writing-mode: tb-rl;
        text-align: center;
        font-size: 1.5rem;
        background: rgb(102, 100, 100);
        padding-right: 0.5rem;
        color: #fff;
        text-shadow: 0rem 0rem 0.5rem #000;
      }
      .wx-img {
        width: 20.5rem;
        height: 100%;
        .wxqr {
          width: 100%;
          height: 100%;
          background: no-repeat url(../assets/wxQR.jpg);
          background-size: contain;
        }
      }
    }
  }
  .wxQR:hover {
    right: 0rem;
    transition-delay: 0s;
    z-index: 9;
  }
  .activitySelect-con {
    height: auto;
    position: fixed;
    bottom: -40vh;
    right: 2rem;
    z-index: 9999;
    transition: all 2000ms ease-in-out;
    .activitySelect {
      width: 25rem;
      height: auto;
      border: 1px solid #ff8c00;
      border-radius: 1rem;
      background-color: #fff;
      margin-bottom: 1rem;
      .activity-name {
        height: 4rem;
        line-height: 4rem;
        font-size: 1.8rem;
        border-bottom: 1px solid #ccc;
      }
      .activity-con {
        font-size: 1.6rem;
        div {
          height: 4rem;
          span {
            margin-right: 0.5rem;
          }
        }
      }
      .activity-btn {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-around;
        div {
          background: #ff8c00;
          color: #fff;
          height: 3rem;
          line-height: 3rem;
          font-size: 1.6rem;
          width: 9rem;
          text-align: center;
          border-radius: 0.5rem;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
  .activitySelect-con.active {
    bottom: 2rem;
  }
}
.dialog {
  z-index: 10000 !important;
  .logo-in {
    text-align: left;
    margin-left: 12%;
    .name,
    .pwd,
    .code {
      margin-bottom: 0.8rem;
      line-height: 4rem;
      span {
        width: 7.5rem;
        display: inline-block;
      }
      .el-input {
        width: 24rem;
      }
    }
    .code {
      display: flex;
      flex-wrap: nowrap;
      .code-img {
        width: 10rem;
        height: 4.2rem;
      }
      .yan-img {
        margin-left: 0.5rem;
      }
    }
  }
  .logo-intwo {
    text-align: left;
    margin-left: 12%;
    .name,
    .pwd,
    .code {
      margin-bottom: 0.8rem;
      line-height: 4rem;
      span {
        width: 9.5rem;
        display: inline-block;
        text-align: right;
      }
      .el-input {
        width: 18rem;
      }
    }
    .code {
      display: flex;
      flex-wrap: nowrap;
      span {
        flex-shrink: 0;
      }
      .code-img {
        width: 10rem;
        height: 4.2rem;
      }
      .yan-img {
        margin-left: 0.5rem;
      }
    }
  }
  .backMsg {
    position: absolute;
    left: 5rem;
    bottom: 2rem;
    padding: 1rem;
  }
  .backRegister {
    position: absolute;
    left: 12rem;
    bottom: 2rem;
    padding: 1rem;
    font-size: 16px;
  }
  .el-button {
    height: 4rem;
  }
}
</style>
<style lang="less">
// .el-input__inner {
//   height: 4rem;
//   line-height: 4rem;
//   font-size: 1.3rem;
// }
</style>

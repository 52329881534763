<template>
  <div class="bottom">
    <div class="bottom-con desktop-present">
      <div class="content">
        <div class="text">关于我们-联系方式-版权声明</div>
        <div class="text">
          <span>主管：中共江苏省委宣传部</span>
          <span>主办：新华报业传媒集团</span>
          <span>承办：新华日报社全媒体视觉中心</span>
          <span>(C)2014-2015 江苏新华报业传媒集团有限公司</span>
        </div>
        <div class="text">
          江苏新华报业传媒集团有限公司 版权所有
          <a href="https://beian.miit.gov.cn" target="_blank"> 苏ICP备05012207号</a>
        </div>
      </div>
    </div>
    <div class="bottom-con mobile-present">
      <div class="content">
        <div class="text">关于我们-联系方式-版权声明</div>
        <div class="text-content">
          <span>主管：中共江苏省委宣传部</span>
          <span>主办：新华报业传媒集团</span>
          <span>承办：新华日报社全媒体视觉中心</span>
          <span>(C)2014-2015 江苏新华报业传媒集团有限公司</span>
        </div>
        <div class="text">
          江苏新华报业传媒集团有限公司 版权所有
          <a href="https://beian.miit.gov.cn" target="_blank"> 苏ICP备05012207号</a>
        </div>
      </div>
    </div>
    <div class="author-show">底图 孙越摄</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bottom {
  position: relative;
  height: auto;
  width: 100%;
  background: no-repeat 50% / cover url(../assets/bottom2.jpg);
  overflow: hidden;
  .author-show {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
  }
  .bottom-con.desktop-present {
    height: 14.7rem;
    background: rgba(0, 0, 0, 0.3);
    padding-top: 1rem;
    position: relative;
    .content {
      width: 120rem;
      margin: 3rem auto 0rem;
      height: 11.5rem;
      font-size: 1.2rem;
      .text {
        height: 3.5rem;
        width: 100%;
        color: #fff;
        span {
          margin-right: 3rem;
        }
        a {
          color: #fff !important;
          text-decoration: none;
        }
      }
    }
  }
  .bottom-con.mobile-present {
    height: 120px;
    background: rgba(0, 0, 0, 0.3);
    padding-top: 1rem;
    position: relative;
    width: 750px;
    .content {
      width: 750px;
      margin: 0 auto;
      height: 120px;
      font-size: 14px;
      .text {
        height: 30px;
        width: 100%;
        line-height: 30px;
        color: #fff;
        span {
          margin-right: 3rem;
        }
        a {
          color: #fff !important;
          text-decoration: none;
        }
      }
      .text-content {
        height: auto;
        color: #fff;
        line-height: 25px;
        span {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
